<!-- User rounded button -->
<button mat-icon-button [matMenuTriggerFor]="userActions">
  @if (user.photoProfileId?.value) {
    <img class="rounded-full w-7 h-7" alt="avatar image" [src]="avatarImgUrl" />
  }

  @if (!user.photoProfileId?.value) {
    <div class="w-7 h-7 rounded-full text-[10px] bg-primary flex justify-center items-center">
      {{ avatarFirstName.charAt(0) + avatarLastName.charAt(0) }}
    </div>
  }
</button>

<!-- Button menu -->
<mat-menu [xPosition]="'before'" #userActions="matMenu">
  <!-- Representante legal -->
  @if (user?.userType === 'entity') {
    <button mat-menu-item>
      <span class="flex flex-col leading-none">
        <span>Representante legal</span>
        <span class="mt-1.5 text-md font-medium">
          {{ user?.manager?.firstname }} {{ user?.manager?.surname }}
        </span>
      </span>
    </button>
  }

  @if (user?.userType === 'entity') {
    <mat-divider class="my-2"></mat-divider>
  }

  <!-- Actividad general -->
  <button mat-menu-item [routerLink]="['/dashboard/activities']">
    <mat-icon [svgIcon]="'heroicons_outline:queue-list'"></mat-icon>
    <span>Actividad general</span>
  </button>

  @if (user?.userType !== 'entity') {
    <!-- Invertir como sociedad -->
    <button mat-menu-item [routerLink]="['/entity/activate']">
      <mat-icon [svgIcon]="'heroicons_outline:building-office'"></mat-icon>
      <span>Invertir como sociedad</span>
    </button>
  }

  <!-- Mi cuenta - Active -->
  @if (canGotoMiPerfil) {
    <button mat-menu-item [routerLink]="['/profile']">
      <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
      <span>Mi cuenta</span>
    </button>
  }

  <!-- Mi cuenta - Disabled -->
  @if (!canGotoMiPerfil) {
    <div
      matTooltip="Podrás acceder a tu cuenta una vez que hayas completado el Paso 3"
      matTooltipPosition="right"
      #miPerfilTooltip="matTooltip"
      (click)="miPerfilTooltip.toggle()"
    >
      <button mat-menu-item disabled>
        <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        <span>Mi cuenta</span>
      </button>
    </div>
  }

  <!-- Preguntas frecuentes -->
  <button mat-menu-item>
    <a
      href="https://intercom.help/betterplan-advisors/es"
      target="_blank"
      rel="noopener noreferrer"
      class="flex items-center"
    >
      <mat-icon [svgIcon]="'mat_outline:help_outline'"></mat-icon>
      <span>Preguntas frecuentes</span>
    </a>
  </button>

  <!-- Mostrar valores del debug -->
  @if (isDebugMode) {
    <button mat-menu-item (click)="showDebugPage()">
      <mat-icon [svgIcon]="'heroicons_outline:cog'"></mat-icon>
      <span>Mostrar valores del debug</span>
    </button>
  }

  <!-- Cambiar de usuario -->
  @if (isAdminUser || canChangeUser) {
    <button mat-menu-item [routerLink]="['/companies']">
      <mat-icon [svgIcon]="'mat_solid:people_outline'"></mat-icon>
      <span>Cambiar de usuario</span>
    </button>
  }

  <!-- Cambiar imagen de usuario -->
  <button mat-menu-item (click)="avatarInput.click()">
    <mat-icon [svgIcon]="'mat_solid:add_a_photo'"></mat-icon>
    <span>Cambiar imagen de usuario</span>
  </button>

  <mat-divider class="my-2"></mat-divider>

  <!-- Cerrar sesión -->
  <button mat-menu-item (click)="onLogout()">
    <mat-icon [svgIcon]="'heroicons_outline:arrow-right-on-rectangle'"></mat-icon>
    <span>Cerrar sesión</span>
  </button>
</mat-menu>

<!-- Img file input -->
<input
  #avatarInput
  hidden
  class=""
  type="file"
  title=" "
  accept="image/*"
  (change)="onAddFile($event)"
/>
