import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

import { MatIconModule } from '@angular/material/icon';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { BackService } from '@bp-core/src/lib/services/back.service';
import { fuseAnimations } from '@fuse/animations';
import { FuseLoadingBarComponent } from '@fuse/components/loading-bar';
import { FuseConfig, FuseConfigService } from '@fuse/services/config';
import { RedirectService } from 'projects/bp-core/src/lib/services/portal/redirect.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'bp-steps-layout',
  templateUrl: './bp-steps.component.html',
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
  standalone: true,
  imports: [MatIconModule, RouterOutlet, FuseLoadingBarComponent, MatButtonModule, RouterLink],
})
export class BpStepsLayoutComponent implements OnInit, OnDestroy {
  isFirstStep: boolean | undefined;

  appConfig: FuseConfig;
  unsubscribe$: Subject<boolean> = new Subject<boolean>();
  constructor(
    public backService: BackService,
    private location: Location,
    private _fuseConfigService: FuseConfigService,
    private router: Router,
    private redirectService: RedirectService,
  ) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.unsubscribe();
  }

  ngOnInit() {
    this._fuseConfigService.config$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((config: FuseConfig) => {
        // Store the config
        this.appConfig = config;
      });
    this.backService.initializeService();
    this.backService.isFirstStepObs.pipe(takeUntil(this.unsubscribe$)).subscribe(isFirstStep => {
      setTimeout(() => {
        this.isFirstStep = isFirstStep;
      }, 0);
    });
  }
  back() {
    this.backService.back();
  }
  close() {
    //solucion temporal
    /*let urlArray = this.router.url.split('/').filter(segment => segment !== '');
    if (urlArray.length > 1 && (urlArray[0] == 'deposit' || urlArray[0] == 'rescue')) {
      this.redirectService.redirect(environment.urlPortal + '/goals/' + urlArray[1]);
      return;
    }
    this.redirectService.redirect(environment.urlPortal);*/

    let urlArray = this.router.url.split('/').filter(segment => segment !== '');
    urlArray = urlArray.map(segment => segment.split('?')[0]);
    if (urlArray.length == 2 && urlArray[0] === 'entity' && urlArray[1] === 'new') {
      this.router.navigate(['/dashboard']);
      return;
    }
    if (urlArray.length == 3 && urlArray[0] === 'entity' && urlArray[1] === 'onboarding') {
      this.router.navigate(['/entity/activate']);
      return;
    }
    if (
      urlArray.length > 1 &&
      urlArray[0] == 'goals' &&
      (urlArray[1] == 'new' || urlArray[1] == 'start')
    ) {
      this.router.navigate(['/dashboard']);
      return;
    }
    if (
      urlArray.length > 1 &&
      (urlArray[0] == 'deposit' || urlArray[0] == 'rescue' || urlArray[0] == 'goals')
    ) {
      this.router.navigate(['/goals', urlArray[1]], { fragment: 'summary' });
      return;
    }

    if (
      urlArray.length > 0 &&
      [
        'onboarding',
        'schedule-advisor',
        'validateci',
        'contract',
        'discover',
        'confirmation',
      ].includes(urlArray[0])
    ) {
      this.router.navigate(['/dashboard']);
      return;
    }

    this.location.back();
  }
}
