
      .bg-office {
        background-color: #363636 !important;
      }
      .bg-endow {
        background-color: #ffffff !important;
      }
      .bg-normal {
        background-color: #27272a !important;
      }
    